<script setup lang="ts">
import { useBonusService } from '~/composables/services/useBonusService';
import type {
  CasinoBonus,
  CasinoBonusStack,
} from '~/interfaces/dto/bonus/response';
import Button from '~/components/design-system/button.vue';
import { QuestionVideoIcon } from '~/components/design-system/icons/index';
import { useAuthStore } from '~/stores/auth.store';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import { tabsPassthrough } from '~/components/passthroughs/tabs';

const auth = useAuthStore();
const site = useSiteStore();
const router = useRouter();
const bonusService = useBonusService();
const bonusTab = ref<string>('0');

const bonus = ref<CasinoBonusStack | null>(null);
const bonusArchive = ref<CasinoBonus[] | null>(null);

const potentialForfeit = ref<string | null>(null);

const loading = ref<boolean>(true);

async function getBonusWallet() {
  try {
    loading.value = true;
    bonus.value = null;
    bonusArchive.value = null;
    await bonusService
      .getBonusStack()
      .then((data) => {
        loading.value = false;
        bonus.value = data;
      })
      .catch((e) => {
        loading.value = false;
        console.error(e);
      });
    const today = new Date();
    const archiveTime = new Date().setMonth(today.getMonth() - 6);
    await bonusService
      .getArchiveBonusStack({
        toDate: today.toISOString().split('T')[0],
        fromDate: new Date(archiveTime).toISOString().split('T')[0],
      })
      .then((data) => {
        bonusArchive.value = data;
        loading.value = false;
      })
      .catch((e) => {
        loading.value = false;
        console.error(e);
      });
  } catch (e) {
    loading.value = false;
    console.error(e);
  }
}

onMounted(async () => {
  await getBonusWallet();
});

function setPotentialForfeit(id: string) {
  potentialForfeit.value = id;
}

async function forfeitBonus(id: string) {
  loading.value = true;
  await bonusService
    .forfeitBonus(id)
    .then(async () => {
      bonus.value = null;
      potentialForfeit.value = null;
    })
    .finally(
      async () =>
        await bonusService.getBonusStack().then((data) => {
          bonus.value = data;
          loading.value = false;
        })
    );
}

async function refreshBonusWallet() {
  await getBonusWallet();
}

function howToClicked() {
  auth.toggleAccountModal(false);
  site.deactivateModal();
  router.push('/faq?accordion=accordionBonusWallet');
}
</script>

<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="$t('bonus-wallet')"
      return-route="history"
    >
      <template #refresh>
        <Button
          v-if="bonusArchive?.length"
          type="secondary"
          rounding="md"
          @click="refreshBonusWallet"
        >
          <ArrowPathIcon class="w-5" />
        </Button>
      </template>
    </LazyUserAccountSettingsHeader>
    <LazyDesignSystemModal
      :active="!!potentialForfeit"
      :title="$t('forfeit-bonus-title')"
      @close-modal="potentialForfeit = null"
    >
      <template #modal-content>
        <div
          class="h-5rem flex items-center justify-center text-black dark:text-white"
        >
          {{ $t('forfeit-bonus-confirmation') }}
        </div>
        <div class="grid grid-cols-2 gap-2 p-3 bg-light-200 dark:bg-dark-900">
          <Button
            group="primary"
            class="justify-center"
            @click="potentialForfeit = null"
          >
            {{ $t('back-to-bonus-wallet') }}
          </Button>
          <Button
            group="secondary"
            class="justify-center"
            @click="forfeitBonus(potentialForfeit)"
          >
            {{ $t('proceed') }}
          </Button>
        </div>
      </template>
    </LazyDesignSystemModal>
    <Tabs
      :value="bonusTab"
      class="bonus-tabs w-full allow-scroll my-2 px-2"
      @update:value="($event) => (bonusTab = $event)"
    >
      <TabList :pt="tabsPassthrough.tabList" class="mb-4">
        <Tab value="0">
          <div class="flex flex-row background-none gap-2">
            <Button
              :type="bonusTab === '0' ? 'tertiary' : 'transparent'"
              rounding="lg"
            >
              {{ $t('bonus-wallet') }}
            </Button>
            <div
              class="how-to-video-div cursor-pointer bg-light-50 dark:bg-dark-900 b-2 rounded-md dark:b-dark-700 flex items-center px-2"
              :title="$t('bonus-wallet-how-to-icon-tooltip')"
              @click="howToClicked()"
            >
              <p
                class="my-0 text-dark-700 dark:text-white mr-2 font-bold text-base line-height-1"
              >
                {{ $t('how-to') }}
              </p>

              <QuestionVideoIcon />
            </div>
          </div>
        </Tab>
        <Tab value="1" v-if="false">
          <Button
            :type="bonusTab === '1' ? 'tertiary' : 'transparent'"
            rounding="lg"
          >
            {{ $t('past-bonuses') }}
          </Button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <div class="pb-6 pt-2">
            <LazyUserInterfaceGenericLoader v-if="!bonus && loading" />
            <div v-else>
              <LazyAccountHistoryBonusWalletCard
                v-if="!!bonus?.activeCasinoBonus"
                :bonus="bonus?.activeCasinoBonus"
              />
              <div v-if="!!bonus?.pendingCasinoBonuses?.length">
                <LazyAccountHistoryBonusWalletCard
                  v-for="(pendingBonus, index) in bonus?.pendingCasinoBonuses"
                  :key="index"
                  :bonus="pendingBonus"
                  :is-active="false"
                  @forfeit="setPotentialForfeit($event)"
                />
              </div>
            </div>
            <div
              v-if="
                !loading &&
                !bonus?.activeCasinoBonus &&
                !bonus?.pendingCasinoBonuses?.length
              "
            >
              <LazyUserInterfaceGenericError state="info">
                {{ $t('no-bonuses') }}
              </LazyUserInterfaceGenericError>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="1">
          <template #header>
            <Button
              :group="bonusTab === 1 ? 'tertiary' : 'link'"
              :prevent-active="true"
              class="dark:text-white"
            >
              {{ $t('past-bonuses') }}
            </Button>
          </template>

          <div class="pb-6">
            <LazyUserInterfaceGenericLoader v-if="!bonus && loading" />
            <div v-else>
              <div v-if="!!bonusArchive?.length">
                <LazyAccountHistoryBonusWalletCard
                  v-for="(archiveBonus, index) in bonusArchive"
                  :key="index"
                  :bonus="archiveBonus"
                  :is-active="false"
                  :is-archive="true"
                />
              </div>
            </div>
            <div v-if="!loading && !bonusArchive?.length">
              <LazyUserInterfaceGenericError state="info">
                {{ $t('no-past-bonuses') }}
              </LazyUserInterfaceGenericError>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<style lang="scss">
.bonus-tabs {
  [data-pc-section='navcontainer'] {
    display: flex;
    justify-content: center;
    [data-pc-section='navcontent'] {
      max-width: max-content;
      ul {
        margin: 8px 0 16px;
        padding: 2px;
        padding-inline-start: 2px;
        border-radius: 8px;
        list-style: none !important;
        display: flex;
        justify-content: center;
        [aria-hidden='true'] {
          display: none;
        }
      }
    }
  }
}

.bonus-wallet-button-border {
  border: 2px solid #2b303b !important;
}

.how-to-video-div:hover {
  background: #eceff6 !important;
  .dark & {
    background: #2b303b !important;
  }
}
</style>
<style scoped lang="scss">
.allow-scroll {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
